@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply rounded-full;
}
h1.h1 {
  @apply text-primary font-thin text-4xl;
}
h2.h2 {
  @apply text-primary font-thin text-xl;
}
hr.hr {
  @apply bg-gradient-to-r from-secondary to-accent h-[2px] w-full mb-6 p-0;
}
hr.hr-accent {
  @apply bg-neutral h-[2px] w-full mb-6 p-0;
}

.easepick-wrapper {
  z-index: 1;
}

#swagger-ui {
  .swagger-ui .opblock {
    background: rgba(97, 175, 254, .1);
    @apply border-primary bg-primary/10;

    &.opblock-get .opblock-summary-method{
      @apply bg-primary;
    }
    &.opblock-post .opblock-summary-method{
      @apply bg-accent;
    }
    &.opblock-delete .opblock-summary-method{
      @apply bg-warning;
    }
    &.opblock-patch .opblock-summary-method{
      @apply bg-secondary;
    }
  }
  .topbar{
    display:none!important
  }
  .information-container {
    a {
      @apply text-primary;
    }
    .info {
      margin: 8px 0px!important;
      h2 {
        @apply text-primary;
        font-size: 24px;
        small, .version {
          @apply bg-info rounded-full px-2 text-xs;
        }
      }
    }
    .main {
      .base-url
      {
        display:none!important
      }
    }
  }
  .highlight-code {
    @apply p-3 bg-[#333] rounded-md;
  }
}

.markdown-doc {
  h1 {
    @apply text-primary font-thin text-4xl py-2;
  }
  h2 {
    @apply text-primary font-thin text-2xl py-2;
  }
  hr {
    @apply py-3;
  }
  p {
    @apply py-3;
  }
  li {
    @apply list-disc ml-4;
  }
  hr {
    @apply bg-gradient-to-r from-secondary to-accent h-[2px] w-full my-3 p-0;
  }
  blockquote {
    @apply alert alert-warning py-0 my-2;
  }
  code {
    @apply bg-neutral py-0.5 px-2 rounded-md;
  }
}